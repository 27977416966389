<template>
  <v-img
    v-bind="$attrs"
    :src="imageUrl"
    :alt="alt"
    :contain="contain"
    :lazySrc="lazySrc"
    :srcset="srcset"
    :sizes="sizes"
    :position="position"
    :transition="transition"
    :height="height"
    :width="width"
    @error="errorHandler"
  />
</template>

<script>
import { VImg } from 'vuetify/components/VImg'; // v-img

import errorimage from '@/assets/images/error.png'; //defualt error image

export default {
  name: 'eImg',
  components: { VImg },
  created() {
    this.imageUrl = this.src ?? this.errorImageUrl;
  },
  props: {
    alt: String,
    contain: Boolean,
    src: {
      type: [String, Object],
      default: '',
    },

    lazySrc: String,
    srcset: String,
    sizes: String,
    position: {
      type: String,
      default: 'center center',
    },
    transition: {
      type: [Boolean, String],
      default: 'fade-transition',
    },
    height: String,
    width: String,
    errorImageUrl: {
      type: String,
      default: errorimage,
    },
  },
  data: () => ({
    imageUrl: '',
  }),
  methods: {
    errorHandler() {
      this.imageUrl = this.errorImageUrl;
    },
  },
  watch: {
    src: function (val) {
      this.imageUrl = val ?? this.errorImageUrl;
    },
  },
};
</script>
