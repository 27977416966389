import { defineStore } from 'pinia';

export const useGlobalVariableStore = defineStore('global_variables', {
  state: () => ({ isShowFromforPaymentPage: true, checkoutSDKLink: '' }),
  getters: {
    showFromforPaymentPage: (state) => state.isShowFromforPaymentPage,
    getCheckoutSDKLink: (state) => state.checkoutSDKLink,
  },
  actions: {
    setIsShowFromForPaymentPage(val) {
      this.isShowFromforPaymentPage = val;
    },
    setCheckoutSDKLink(val) {
      this.checkoutSDKLink = val;
    },
  },
});
