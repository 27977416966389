<template>
  <div>
    <slot v-if="!paused"></slot>
    <h1 v-else>{{ $t("Sorry, we don't accept payments for the moment") }}</h1>
  </div>
</template>

<script>
export default {
  name: 'CanBePaused',
  props: {
    paused: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
