import { checkoutConfig } from '../services/common';

import Checkout from './CheckoutPage.vue';

export default {
  extends: Checkout,
  name: 'CheckoutReqPay',
  page_title: 'Add your Address and your location on map',
  methods: {
    getTransactionData(id) {
      const headers = { 'accept-language': this.$i18n.locale };

      return checkoutConfig(id, headers).then((res) => {
        if (res.request?.responseURL?.includes('/not-found')) {
          throw new Error('Redirection on 404', { cause: 302 });
        }
        return res.data;
      });
    },
  },
};
