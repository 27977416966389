<template>
  <v-app>
    <AttemptHeader
      v-if="new_layout"
      :logo_url="unit_details.logo_url"
      :header_color="unit_details.header_color"
      :attempt="attempt"
    />
    <v-main v-if="new_layout">
      <slot> </slot>
    </v-main>
    <PaymentFooter
      v-if="new_layout"
      :twitter_url="unit_details.twitter_url"
      :instagram_url="unit_details.instagram_url"
      :facebook_url="unit_details.facebook_url"
    />
  </v-app>
</template>

<script>
import AttemptHeader from './AttemptHeader.vue';
import PaymentFooter from './PaymentFooter.vue';

export default {
  name: 'CustomerPage',
  props: {
    unit_details: Object,
    new_layout: Boolean,
    attempt: Boolean,
  },
  components: { AttemptHeader, PaymentFooter },
};
</script>
