export default {
  data: () => ({
    redirect_url: null,
  }),
  computed: {
    pymt_btn_disabled() {
      return (
        this.processing ||
        !!this.redirect_url ||
        this.validation_errors.amount?.length > 0
      );
    },
    getToDetails() {
      const toCustomerName = this.transaction_details.to.find(
        (paymentTo) =>
          paymentTo[this.isCheckout ? 'label' : 'label_en'] === 'Customer name',
      );
      const remainingToInformation = this.transaction_details.to.filter(
        (paymentTo) =>
          paymentTo[this.isCheckout ? 'label' : 'label_en'] !== 'Customer name',
      );

      return {
        toCustomerName: toCustomerName?.value || '',
        toCustomerNameLabel: toCustomerName?.label || '',
        remainingToInformation: remainingToInformation || [],
      };
    },
  },
  methods: {
    processPymtReq(promise) {
      return this.$processReqSilent(promise).then((res) => {
        const { redirect_url } = res.data;
        this.redirect_url = redirect_url;
        if (this.redirect_url) {
          window.location.href = redirect_url;
        }
        return res;
      });
    },
  },
};
