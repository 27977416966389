export async function addCSPMetaTag() {
  const cspHeaders = localStorage.getItem('CSP_HEADER');
  if (cspHeaders && process.env.NODE_ENV === 'production') {
    const meta = document.createElement('meta');
    meta.setAttribute('http-equiv', 'Content-Security-Policy');
    meta.setAttribute('content', cspHeaders);
    document.head.appendChild(meta);
  }
}

export async function removeCSPMetaTag() {
  const meta = document.querySelector(
    'meta[http-equiv="Content-Security-Policy"]',
  );
  if (meta) {
    meta.parentNode.removeChild(meta);
  }
}
