let CMS = class {
  constructor(plugin) {
    this.plugin = plugin.toUpperCase();
  }

  displaySocialIcons() {
    if (
      localStorage.getItem(`VUE_APP_${this.plugin}_PLUGIN_SOCIAL_ICONS`) != null
    ) {
      return (
        localStorage.getItem(`VUE_APP_${this.plugin}_PLUGIN_SOCIAL_ICONS`) ===
        'true'
      );
    }
    return true;
  }
  displayLanguageButton() {
    if (
      localStorage.getItem(`VUE_APP_${this.plugin}_PLUGIN_LANGUAGE_BUTTON`) !=
      null
    ) {
      return (
        localStorage.getItem(
          `VUE_APP_${this.plugin}_PLUGIN_LANGUAGE_BUTTON`,
        ) === 'true'
      );
    }
    return true;
  }
};
export default CMS;
