<template>
  <div v-if="attempt" class="ottu-header" :style="headerStyle">
    <v-container class="pt-3">
      <v-row class="height100" justify="center" align="center">
        <v-col
          class="d-flex fb-80"
          justify="space-between"
          cols="11"
          sm="10"
          md="12"
          xl="6"
          height="100px"
        >
          <v-row style="width: 100%" justify="space-between" align="center">
            <div>
              <eImg :src="logo_url" :error-image-url="logoerror" />
            </div>
            <a
              v-show="manageLanguageButton"
              href="#0"
              class="lang-toggler"
              :class="textColorClass"
              @click.prevent="
                changeLanguage(getCurrentLanguage.title === 'AR' ? 'en' : 'ar')
              "
            >
              {{
                $t(getCurrentLanguage.title === 'AR' ? 'English' : 'بالعربي')
              }}
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <header v-else class="ottu-header" :style="headerStyle">
    <div>
      <eImg :src="logo_url" :error-image-url="logoerror" />
    </div>
    <a
      v-show="manageLanguageButton"
      href="#0"
      class="lang-toggler"
      :class="textColorClass"
      @click.prevent="
        changeLanguage(getCurrentLanguage.title === 'AR' ? 'en' : 'ar')
      "
    >
      {{ $t(getCurrentLanguage.title === 'AR' ? 'English' : 'بالعربي') }}
    </a>
  </header>
</template>

<script>
import { wc_hex_is_light } from '../lib/color';
import LanguageSwitchMixin from '../mixins/LanguageSwitchMixin';
import CMS from '../navbar_helper';

import logoerror from '@/assets/images/logo-placeholder.png';
import eImg from '@/components/eImg';

export default {
  name: 'AttemptHeader',
  mixins: [LanguageSwitchMixin],
  components: { eImg },
  props: {
    logo_url: String,
    header_color: String,
    attempt: Boolean,
  },
  created() {
    this.cms = new CMS(this.$route.meta.plugin);
  },
  data: () => ({
    languages: [
      { code: 'en', title: 'EN' },
      { code: 'ar', title: 'AR' },
    ],
    cms: CMS,
  }),
  computed: {
    logoerror: () => logoerror,
    getCurrentLanguage() {
      return this.languages.find((lang) => {
        return lang.code === this.$i18n.locale;
      });
    },
    headerStyle() {
      const styleObj = {};
      if (this.header_color) {
        if (this.header_color === 'transparent')
          styleObj.backgroundColor = this.header_color;
        else styleObj.backgroundColor = `#${this.header_color}`;
      }
      return styleObj;
    },
    textColorClass() {
      if (this.header_color) {
        if (!wc_hex_is_light(this.header_color)) {
          return 'white--text';
        } else {
          return 'black--text';
        }
      }
      return '';
    },
    manageLanguageButton() {
      return this.cms.displayLanguageButton();
    },
  },
  methods: {
    changeLanguage(newlang) {
      if (newlang !== this.$i18n.locale) {
        this.languages = this.languages.reverse();
        this.switchLanguage();
      }
    },
  },
};
</script>

<style scoped>
/* Header */
header {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  z-index: 5;
}

.fb-80 {
  flex-basis: 75%;
}

@media (max-width: 768px) {
  .fb-80 {
    flex-basis: 100%;
  }
}
</style>
