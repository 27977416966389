<template>
  <v-layout v-if="attempt" class="order-details-layout">
    <v-expansion-panels>
      <v-expansion-panel
        class="order-details-accordion success-accordion side-arrow border-none-m attempt-accordion"
      >
        <v-expansion-panel-title class="rounded-0">
          <div class="accordion-header">
            <img src="@/assets/svgs/payments/order-description.svg" />
            <div class="accordion-heading">
              <span class="icon-text">{{ $t('Order description') }}</span>
              <span class="mr detail-num">{{
                transaction_details.order_description['Merchant order no'] ||
                transaction_details.order_description['Order no'] ||
                transaction_details.order_description[
                  'Order ID | Invoice No | Booking'
                ]
              }}</span>
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row class="pa-5 pt-1">
            <v-col
              v-for="(iprop, parentindex) in customerDetailAndExtraData"
              :key="parentindex"
              :cols="12"
              :sm="iprop.length <= breakpoint ? 6 : 12"
              :md="iprop.length <= breakpoint ? 3 : 6"
            >
              <v-card
                class="re-ma-10 rounded-10 card-shadow"
                data-test="dynamic-order-desc-card"
              >
                <v-card-text>
                  <div class="detail-head">{{ $t(iprop.title) }}</div>
                  <div
                    :class="
                      iprop.title &&
                      iprop.title.includes('phone') &&
                      $i18n.locale == 'ar'
                        ? 'detail-body break-all dir-ltr'
                        : 'detail-body break-all'
                    "
                  >
                    {{ iprop.content }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-card
                class="re-ma-10 rounded-10 card-shadow"
                data-test="bottom-details-card"
              >
                <v-card-text>
                  <div class="detail-body d-flex justify-start">
                    <div class="detail-left">
                      <div class="detail-head">
                        <img src="@/assets/svgs/payments/date.svg" />
                        <span>{{
                          (!isArabic
                            ? getBottomDetails.bottomDate.label_en
                            : getBottomDetails.bottomDate.label_ar) ||
                          getBottomDetails.bottomDate.label
                        }}</span>
                      </div>
                      <div data-test="detail-body">
                        {{ getBottomDetails.bottomDate.value }}
                      </div>
                    </div>
                    <div class="detail-right">
                      <div class="detail-head">
                        <img src="@/assets/svgs/payments/internal-ID.svg" />
                        <span>{{
                          (!isArabic
                            ? getBottomDetails.bottomInternalId.label_en
                            : getBottomDetails.bottomInternalId.label_ar) ||
                          getBottomDetails.bottomInternalId.label
                        }}</span>
                      </div>
                      <div class="detail-body break-all">
                        {{ getBottomDetails.bottomInternalId.value }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-layout>
  <v-expansion-panel
    v-else
    data-test="order-description"
    class="order-description"
  >
    <v-expansion-panel-title>
      <span>{{ $t('Order Description') }} </span>
      <span class="mr detail-num">{{
        transaction_details.order_description['Merchant order no'] ||
        transaction_details.order_description['Order no'] ||
        transaction_details.order_description['Order ID | Invoice No | Booking']
      }}</span>
    </v-expansion-panel-title>
    <v-expansion-panel-text eager>
      <ul>
        <li class="break-word">
          <span>{{
            (!isArabic
              ? getBottomDetails.bottomDate.label_en
              : getBottomDetails.bottomDate.label_ar) ||
            getBottomDetails.bottomDate.label
          }}</span>
          <span>{{ getBottomDetails.bottomDate.value }}</span>
        </li>
        <li class="break-word">
          <span>{{
            (!isArabic
              ? getBottomDetails.bottomInternalId.label_en
              : getBottomDetails.bottomInternalId.label_ar) ||
            getBottomDetails.bottomInternalId.label
          }}</span>
          <span>{{ getBottomDetails.bottomInternalId.value }}</span>
        </li>
        <li
          v-for="(iprop, parentindex) in customerDetailAndExtraData"
          :key="parentindex"
          class="break-word"
        >
          <span>{{ $t(iprop.title) }}</span>
          <span
            :class="
              iprop.title &&
              iprop.title.includes('phone') &&
              $i18n.locale == 'ar'
                ? 'detail-body break-all dir-ltr'
                : 'detail-body break-all'
            "
          >
            {{ iprop.content }}</span
          >
        </li>
      </ul>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import '../assets/style.css';
import '../assets/rtl.css';
import paymentMixin from '../mixins/paymentMixin';
export default {
  name: 'OrderDescription',
  mixins: [paymentMixin],
};
</script>

<style scoped>
.order-details-accordion >>> .v-expansion-panel__header::after {
  display: none !important;
}
.order-details-accordion >>> .v-expansion-panel__header__icon {
  display: block;
}
</style>
<style>
.dir-ltr {
  direction: ltr;
  text-align: end !important;
}
.detail-num {
  overflow-wrap: break-word;
}
.detail-body {
  overflow-wrap: break-word;
}
.v-expansion-panel-title--active > .v-expansion-panel-title__overlay,
.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup='menu'][aria-expanded='true']
  > .v-expansion-panel-title__overlay {
  opacity: 0 !important;
}
.v-expansion-panel-title {
  padding: 0px;
}
</style>
