export default {
  methods: {
    switchLanguage() {
      // TODO: select from list of available languages
      const target_lang = this.$i18n.locale === 'en' ? 'ar' : 'en';

      this.$router
        .push({
          name: this.$route.name,
          params: { ...this.$route.params, lang: target_lang },
        })
        .catch((err) => {
          if (
            err &&
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes(
              'Avoided redundant navigation to current location',
            )
          ) {
            console.log('Error: ', err);
          }
        });
      this.$i18n.locale = target_lang;
      this.$vuetify.locale.current = target_lang;
    },
  },
};
